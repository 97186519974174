import React, { Component, useEffect } from 'react';
import "./AboutSelector.css"

function AboutSelector(props) {

    const setLanguage = (language) => {
        props.setSelectedLanguage(language);
    }

    return (
        <div id='selector-container'>
            <button class="button-82-pushable" role="button" onClick={() => setLanguage('en')}>
                <span class="button-82-shadow"></span>
                <span class="button-82-edge"></span>
                <span class="button-82-front text">
                    EN
                </span>
            </button>
            <button class="button-82-pushable" role="button" onClick={() => setLanguage('fr')}>
                <span class="button-82-shadow"></span>
                <span class="button-82-edge"></span>
                <span class="button-82-front text">
                    FR
                </span>
            </button>
        </div>
    )
}

export default AboutSelector