import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = (props) => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  return (
    <section id="about">
      <Container>
          <Title title={t('button.about')} />
          <Row className="about-wrapper">
            <Col md={6} sm={12}>
              <Fade bottom duration={1000} delay={600} distance="30px">
                <div className="about-wrapper__image">
                  <AboutImg alt="profile picture" filename={img} />
                </div>
              </Fade>
            </Col>
            <Col md={6} sm={12}>
              <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
                <div className="about-wrapper__info">
                  <p className="about-wrapper__info-text">
                    {t('aboutParagraph.p1')}
                  </p>
                  <p className="about-wrapper__info-text">
                    {t('aboutParagraph.p2')}
                  </p>
                  <p className="about-wrapper__info-text">
                    {t('aboutParagraph.p3')}
                  </p>
                  {resume && (
                    <span className="d-flex mt-3">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--resume"
                        href={resume}
                      >
                        {t('button.resume')}
                      </a>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--resume"
                        href={"/photos"}
                        style={{ marginLeft: 10 }}
                      >
                        {t('button.photography')}
                      </a>
                    </span>
                  )}
                </div>
              </Fade>
            </Col>
          </Row>
      </Container>
    </section>
  );
};

export default About;
