import React, { useContext, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';

const Contact = (props) => {
  const { contact } = useContext(PortfolioContext);
  const { cta, btn, email } = contact;
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  return (
    <section id="contact">
      <Container>
        <Title title={t('contact')} />
        <Fade bottom duration={1000} delay={800} distance="30px">
          <div className="contact-wrapper">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : 'https://github.com/cobidev/react-simplefolio'}
            >
              {btn || "Let's Talk"}
            </a>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Contact;
