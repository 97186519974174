import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { translate } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

//import Particles from 'react-particles-js';
import Particles from "react-tsparticles";

const Header = (props) => {
  const { hero } = useContext(PortfolioContext);
  const { title, name, subtitle, cta } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  return (

    <section id="hero" className="jumbotron" style={{ alignItems: 'center', justifyContent: 'center' }}>

      <Container style={{ alignItems: 'center', marginLeft: isDesktop ? 30 : 0 }}>

        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            <span className="text-color-main">{"Vivek Kandathil"}</span>
          </h1>
        </Fade>

        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <p className="hero-cta">
            <span className="cta-btn cta-btn--hero" style={{ marginRight: 20 }}>
              <Link to="about" smooth duration={1000}>
                {t('button.about')}
              </Link>

            </span>
            <span className="cta-btn cta-btn--hero">
              <Link to="projects" smooth duration={1000}>
                {t('button.projects')}
              </Link>
            </span>
          </p>
        </Fade>
      </Container>
      {
        isMobile ? (<div></div>) :
          (<Particles
            id="tsparticles"
            style={{ marginRight: 10 }}
            options={{
              background: {
                color: {
                  value: "white",
                },
              },
              fpsLimit: 60,
              interactivity: {
                detectsOn: "canvas",
                events: {
                  onHover: {
                    enable: true,
                    mode: "grab",
                  },
                  resize: true,
                },
                modes: {
                  bubble: {
                    distance: 400,
                    duration: 2,
                    opacity: 0.8,
                    size: 40,
                  },
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#e60000",
                },
                links: {
                  color: "#b30000",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  direction: "none",
                  enable: true,
                  outMode: "bounce",
                  random: false,
                  speed: 4,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    value_area: 1000,
                  },
                  value: 80,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  random: true,
                  value: 3,
                },
              },
              detectRetina: true,
            }}
          />)
      }
    </section >

  );
};

export default Header;
